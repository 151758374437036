@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500&display=swap");

body {
  margin: 0;
  /* font-family: "Inter", sans-serif; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0d1f24;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  box-sizing: border-box;
}

.slate-a {
  word-break: break-word;
}

blockquote {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0px;
  margin-right: 0px;
  border-left: 2px solid #ddd;
  padding: 10px 20px 10px 16px;
  color: #aaa;
}

h1,
h2,
h3,
h4 {
  font-family: "Poppins", sans-serif;
}

/* .slate-hashtag {
  background-color: cornflowerblue;
  padding: 2px 4px;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
} */
